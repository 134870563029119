import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

export default function TeamMemberSection(props) {
  const { image, title, role, children: description, email } = props;

  return (
    <Container>
      <Row>
        <Col className="team-member-image">
          <img src={image} style={{ width: "100%" }} alt="Heading" />
        </Col>
        <Col sm={9}>
          <h2>{title}</h2>
          <p className="lead">{role}</p>
          <p>{description}</p>
          <p className="mb-0">
            <FontAwesomeIcon icon={faEnvelope} />
            &nbsp;
            {email} &#10;&#13;
          </p>
        </Col>
      </Row>
    </Container>
  );
}

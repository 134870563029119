import * as React from "react";
import Layout from "../components/Layout";
import ImageContainer from "../components/ImageContainer";
import PageSection from "../components/PageSection/PageSection";
import TeamMemberSection from "../components/TeamMemberSection/TeamMemberSection";
import imageMueller from "../images/team/cmueller_cropped.jpg";
import imageSchieder from "../images/team/mschieder_cropped.jpg";
import imageHocevar from "../images/team/ahocevar_cropped.jpg";
import imageHeading from "../images/team2.jpg";
import { FormattedMessage, useIntl } from "react-intl";
import { Helmet } from "react-helmet";
import useFormattedMsg from "../hooks/useFormattedMsg";

const TeamPage = () => {
  const intl = useIntl();
  const title = useFormattedMsg("team.title");
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
      </Helmet>
      <Layout>
        <ImageContainer image={imageHeading} />
        <PageSection title={intl.formatMessage({ id: "team.team_heading" })}>
          <FormattedMessage id="team.team_descr" />
        </PageSection>
        <div className="pb-5">
          <TeamMemberSection
            title="DI Christian Mueller Msc(GIS), MSC(Applied IT Security)"
            image={imageMueller}
            role={intl.formatMessage({ id: "team.team_cmueller_role" })}
            email="christian.mueller@os-solutions.at"
          >
            <FormattedMessage id="team.team_cmueller_descr" />
          </TeamMemberSection>
        </div>
        <div className="pb-5">
          <TeamMemberSection
            title="Ing. Michael Schieder"
            image={imageSchieder}
            role={intl.formatMessage({ id: "team.team_mschieder_role" })}
            email="michael.schieder@os-solutions.at"
          >
            <FormattedMessage id="team.team_mschieder_descr" />
          </TeamMemberSection>
        </div>
        <PageSection
          title={intl.formatMessage({ id: "team.partner_heading" })}
        ></PageSection>
        <div className="pb-5">
          <TeamMemberSection
            title="DI Andreas Hocevar"
            image={imageHocevar}
            role={intl.formatMessage({ id: "team.team_ahocevar_role" })}
            email="andreas.hocevar@os-solutions.at"
          >
            <FormattedMessage id="team.team_ahocevar_descr" />
          </TeamMemberSection>
        </div>
      </Layout>
    </>
  );
};

export default TeamPage;
